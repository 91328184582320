.pagination{
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    padding-top: 40px;
    &__item{
        display: flex;
        li{
            padding-left: 10px;
            padding-right: 10px;
            a{
                color: #352F55;
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                opacity: 0.5; 
                cursor: pointer;  
            }
            &.active{
                a{
                    color: #352F55;
                    opacity: 1; 
                };
            }
            &.pagination-btn{
                a{
                    color: #352F55;
                    opacity: 1; 
                }
            }
        }
    }
}