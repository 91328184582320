html * {
	box-sizing: border-box;
}

body{
    font-family: $font-main;
    font-size: 14px;
 
}

.container{
    max-width: 1248px;
    width: 100%;
    padding: 0 12px;
    margin: 0 auto;
}

h2{
    margin: 0;
    padding: 0;
}

h3{
    margin: 0;
    padding: 0;
}

p{
    padding: 0;
    margin: 0;
}

a{
    text-decoration: none;
    color: $white;
}

ul{
    padding: 0;
    margin: 0;
}

li{
    list-style-type: none;
}

.main{
    background-color: #4045ba;
}

