

.video{
    width: 100%;
    height: 180px;
    background-color: #3D3E49;
}

.videochat{
    &__wrapper{
        display: flex;
        justify-content: space-between;
        @media (max-width: 900px){
            flex-direction: column;
        }
        &-1{
            @media (max-width: 900px){
                flex-direction: column;
            }
        }
        &-2{
            margin-top: 25px;
            padding-bottom: 30px;
        }
    }
    &__col-1{
        width: 100%;
        margin-right: 8px;
        
        &-second{
            display: flex;
            justify-content: space-between;
            @media (max-width: 900px){
                flex-direction: column;
            }
        }
    }
    &__col-2{
        max-width: 277px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        &-first{
            @media (max-width: 900px){
                margin-top: 15px;
            }
        }
        &-second{
            height: 557px;
            @media (max-width: 900px){
                max-width: 900px;
                width: 100%;
                margin-top: 10px;
            }
            
        }
    }
    &__buttons{
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        // padding: 12px;
        padding: 0px 0px 12px 12px;
        // a{
        //     &.btn-video-6{
        //         color: #7298D7;
        //     }
        // }
    }
    &__btn{
        padding-top: 100px;
        &-balance{
            width: 160px;
            height: 64px;
            background-color: $white;
            display: block;
            border-radius: 10px;
            padding: 12px;
            cursor: pointer;
            strong{
                font-size: 14px;
                line-height: 17px;
                color: $blue-magenta;
            }
            p{
                color: $blue-magenta;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                span{
                    font-size: 18px;
                    line-height: 22px;
                    color: $violet;
                    font-weight: 600;
                    padding-right: 5px;
                }
            }
        }
        &-credits{
            width: 108px;
            height: 64px;
            background-color: #40BAB3;
            display: block;
            border-radius: 10px;
            padding: 8px;
            cursor: pointer;
            margin-left: 10px;
            span{
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                &:before{
                    position: absolute;
                    content: "\e90b";
                    font-family: "ntsame";
                    font-size: 24px;
                    transform: translate(-50%, 0%);
                }
            }
            p{
                padding-top: 30px;
                text-align: center;
            }
        }
    }
    
    &__subcol-1{
        max-width: 483px;
        width: 100%;
        @media (max-width: 900px){
            max-width: 900px;
            width: 100%;
        } 
    }
    
    &__profile{
        padding: 15px;
        background-color: $white;
        border-radius: 10px;
        display: flex;
        min-height: 240px;
        margin-bottom: 8px;
        &-colleft{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-name{
                font-size: 18px;
                line-height: 21px;
                color: $blue-magenta;
                font-weight: 600;
            }
            img{
                width: 132px;
                @media (max-width: 1100px){
                    width: 110px;
                }
            }
        }
        &-colright{
            margin-left: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between; 
        }
        &-top{
            display: flex;
            align-items: center;
        }
        &-name{
            font-size: 18px;
            line-height: 21px;
            color: $blue-magenta;
            font-weight: 600;
            font-weight: 500;
        }
        &-btn{
            width: 24px;
            height: 24px;
            background-color: #C8CFD6;
            display: inline-block;
            border-radius: 50%;
            margin-left: 12px;
            position: relative;
            cursor: pointer;
            &:before{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "?";
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
            }
        }
        &-line{
            width: 100%;
            height: 3px;
            background-color: $primary;
            margin-top: 15px;
            margin-bottom: 22px;
        }
        &-item{
            display: flex;
            justify-content: space-between;
            color: $blue-magenta;
            margin-bottom: 15px;
        }
        &-caption{
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
        }
        &-desc{
            font-size: 14px;
            line-height:16px;
            font-weight: 400;
        }
        &-bottom{
            display: flex;
        }
        &-btnstar{
            min-width: 40px;
            height: 40px;
            background: rgba(255, 165, 50, 0.2);
            display: inline-block;
            border-radius: 10px;
            position: relative;
            margin-right: 8px;
            &:before{
                position: absolute;
                content: "\e910";
                font-family: "ntsame";
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #FFA532;
                font-size: 20px;
            }
        }
    }
    &__online{
        background-color: #E5E5E5;
        min-height: 310px;
        border-radius: 10px;
        padding: 8px 40px;
        @media (max-width: 1100px){
            padding: 8px;
        }
        @media (max-width: 900px){
            min-height: 230px;
        }
        &-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
        }
        &-title{
            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            color: $blue-magenta;
            @media (max-width: 1100px){
                font-size: 14px;
            }
        }
        &-form{
            max-width: 288px;
            width: 100%;
            height: 40px;
            background-color: $white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 10px;
            
        }
        input{
            font-size: 12px;
            line-height: 14px;
            color: #a3a3a3;
            padding-left: 12px;
            max-width: 230px;
            width: 100%;
            border: none;
            outline: none;
            &::placeholder{
                color: #a3a3a3;
            }
        }


        &-search{
            width: 20px;
            height: 20px;
            display: block;
            margin-right: 10px;
            cursor: pointer;
            position: relative;
            &:before{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "\e92f";
                font-family: 'ntsame';
                font-size: 18px;
                color: #A3A3A3;

            }
        }
        &-wrap{
            width: 352px;
            margin: 0 auto;
            position: relative;
            .slick-next,
            .slick-prev{
                position: absolute;
                width: 15px;
                height: 20px;
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                &:before{
                    position: absolute;
                    content: "\e903";
                    font-family: "ntsame";
                }
            }
            @media (max-width: 1200px){
                width: 280px;
            }
            @media (max-width: 1000px){
                width: 200px;
            }
            @media (max-width: 900px){
                width: 100%;
            }

            .slick-next{
                right: -40px;
                top: 50%;
                transform: translateY(-50%);
                @media (max-width: 1200px){
                    right: -30px;
                }
                @media (max-width: 900px){
                    right: 10px;
                    top: 115%;
                }
                &:before{
                    right: -1px;
                    top: 0;
                    transform: translateY(0%); 
                    transform: rotate(180deg);
                }
            }
            .slick-prev{
                position: absolute;
                left: -40px;
                top: 50%;
                transform: translateY(-50%);
                @media (max-width: 1200px){
                    left: -30px;
                }
                @media (max-width: 900px){
                    left: 10px;
                    top: 115%;
                }
                &:before{
                    right: 0px;
                    top: 1px;
                    transform: translateY(0%); 
                    
                }
            }
        }

        &-item{
            max-width: 72px;
            margin-left: 8px;
            margin-right: 8px;
            padding-top: 10px; 
            padding-bottom: 5px;
            
        }
        &-image{
            width: 72px;
            height: 72px;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 6px;
            position: relative;
            &:before{
                position: absolute;
                width: 12px;
                height: 12px;
                border: 2px solid #e5e5e5;
                border-radius: 50%;
                background-color: #40BAB3;
                right: -8px;
                top: -8px;
                content: "";
            }
        }
        &-name{
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            margin-top: 5px;
        }
        &-star{
            width: 15px;
            height: 15px;
            position: relative;
            display: block;
            margin: 0 auto;
            margin-top: 5px;
            &:before{
                position: absolute;
                content: "\e901";
                font-family: "ntsame";
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #FFA532;
            }
        }
    }
    &__subcol-2{
        max-width: 446px;
        width: 100%;
        margin-left: 9px;
        height: 557px; //////////////////////////////////
        border: 1px solid #fff;
        border-radius: 10px;
        position: relative;
        @media (max-width: 900px){
            margin-left: 0px;
            margin-top: 10px;
            max-width: 900px;
            width: 100%;
        }
    }
    &__list{
        &-profile{
            width: 100%;
            height: 248px;
            background-color: #3D3E49;
            border-radius: 9px 9px 0px 0px;
        }
        &-item{
            display: flex;
            align-items: center;
            padding-top: 4px;
            padding-left: 7px;
        }
        &-image{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-size: cover;
            margin-right: 8px;
        }
        &-icon{
            width: 20px;
            height: 20px;
            position: relative;
            margin-right: 8px;
            &:before{
                position: absolute;
                content: "\e910";
                left: 0;
                top: -2px;
                color: #FFA532;
                font-family: 'ntsame';
                font-size: 20px;
            }
        }
        &-name{
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            color: $white;
        }
        &-chat::-webkit-scrollbar {
            width: 0px;
        }
        &-chat{
            padding: 10px 8px;
            display: block;
            overflow-y: scroll;
            height: 142px;
            background-color: #fff;
            
            &-youmessage{
                margin-bottom: 5px;
                p{
                    display: inline-block;
                    color: #352F55;
                    padding: 8px;
                    background: rgba(64, 69, 186, 0.1);
                    border-radius: 8px 8px 8px 0px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            &-hismessage{
                text-align: right;
                margin-bottom: 5px;
                p{
                    display: inline-block;
                    color: #352F55;
                    padding: 8px;
                    background: rgba(64, 69, 186, 0.1);
                    border-radius: 8px 8px 0px 8px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        &-wrapper{
            padding: 8px;
            background-color: #fff;
            position: absolute;
            bottom: 48px;
            left: 0;
            right: 0;  
        }
        &-message{
            background-color: #F5F5F5;
            width: 100%;
            border-radius: 10px;
            height: 80px;
            padding: 12px;
            display: flex;
            justify-content: space-between;
            textarea{
                outline: none;
                border: none;
                max-width: 290px;
                width: 100%;
                height: 100%;
                color: #352F55;
                font-size: 14px;
                line-height: 16px;
                background-color: #F5F5F5;
                resize: none;
                &::placeholder{
                    color: #353535;
                }
                @media (max-width: 900px){
                    max-width: 600px;
                }
            }
            &-btn{
                display: flex;
                align-items: flex-end;
            }
            &-attach,
            &-send,
            &-smile{
                position: relative;
                width: 24px;
                height: 24px;
                margin-left: 8px;
                display: inline-block;
                cursor: pointer;
                &:before{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-family: 'ntsame';
                    font-size: 22px;
                    color: grey;
                    
                }
            }
            &-attach{
                &:before{
                    content: "\e933";
                    
                }
            }
            &-send{
                &:before{
                    content: "\e932";
                    
                }
            }
            &-smile{
                &:before{
                    content: "\e931";
                }
            }
        }
        &-endchat{
            font-size: 12px;
            line-height: 14px;
            color: #A3A3A3;
            font-weight: 500;
            margin-top: 8px;
        }
        &-btn{
            width: 100%;
            display: flex;
            position: absolute;
            bottom: 0px;
            left: 0;
            &-first,
            &-second,
            &-third,
            &-fourth{
                background-color: #fff;
                width: 25%;
                height: 48px;
                display: inline-block;
                position: relative;
                &:before{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: "ntsame";
                    font-size: 24px;
                }
                
            }
            &-first{
                border-radius: 0px 0px 0px 9px;
                border-top: 2px solid #4045BA;
                transition: background-color 0.3s;
                &:before{
                    content: "\e906";
                    color: #4045BA;
                }
                &:hover{
                    background-color: #878ac4;
                    opacity: .9;
                    
                }
            }
            &-second{
                border-top: 2px solid #CE49D0;
                transition: background-color 0.3s;
                &:before{
                    content: "\e917";
                    color: #CE49D0;
                }
                &:hover{
                    background-color: #c398c4;
                    opacity: .9;
                }
            }
            &-third{
                border-top: 2px solid #FFA532;
                transition: background-color 0.3s;
                &:before{
                    content: "\e91c";
                    color: #FFA532;
                }
                &:hover{
                    background-color: #e9c69b;
                    opacity: .9;
                }
            }
            &-fourth{
                border-radius: 0px 0px 9px 0px;
                border-top: 2px solid #40BAB3;
                transition: background-color 0.3s;
                &:before{
                    position: absolute;
                    content: "\e934";
                    font-family: 'ntsame';
                    width: 24px;
                    height: 24px;
                    color: #40BAB3;
                }
                &:hover{
                    background-color: #9ddcd8;
                    opacity: .9;
                }  
            }
        }
    }
    &__subcol-3{
        max-width: 277px;
        width: 100%;
        @media (max-width: 900px){
            max-width: 900px;
            width: 100%;
        }
    }
    &__video-block{
        width: 100%;
        height: 180px;
        background-color: #3D3E49;
        border-radius: 10px;
        margin-bottom: 8px;
    }
    &__contacts{
        height: 367px;
        background-color: #fff;
        border-radius: 9px;
        overflow: hidden;
        &-item{
            display: flex;
            justify-content: space-between;
            height: 40px;
            align-items: center;
            background-color: #F2F6FA;
            border-radius: 9px 9px 0px 0px;
            padding-left: 15px;
            padding-right: 15px;
        }
        &-star{
            width: 20px;
            height: 20px;
            position: relative;
            &:before{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "\e910";
                font-family: "ntsame";
                color: #FFA532;
                font-size: 19px;
            }
        }
        &-search{
            display: inline-block;
            width: 20px;
            height: 20px;
            position: relative;
            &:before{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "\e92f";
                font-family: 'ntsame';
                color: #4045BA;
                font-family: 18px;
            }
        }
        &-title{
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            color: #352F55;
        }
        &-box{
            height: 48px;
            border-bottom: 2px solid #C8CFD6;
            display: flex;
            align-items: center;
            position: relative;
            &-wrapper{
                height: 327px;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0px;
                }
            }
        }
        &-image{
            width: 32px;
            height: 32px;
            border-radius: 6px;
            background-size: cover;
            margin-left: 15px;
            position: relative;
            &:before{
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #40BAB3;
                border: 2px solid #F2F6FA;
                right: -4px;
                bottom: -4px;
                content: "";
            }
        }
        &-star{
            width: 20px;
            height: 20px;
            margin-left: 10px;
            position: relative;
            &:before{
                position: absolute;
                content: "\e910";
                font-family: 'ntsame';
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 19px;
                color: #FFA532;
            }
        }
        &-name{
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            margin-left: 10px;
        }
        &-message{
            width: 16px;
            height: 17px;
            position: relative;
            margin-left: 10px;
            cursor: pointer;
            &:before{
                position: absolute;
                content: "\e917";
                font-family: "ntsame";
                color: #CE49D0;
                font-size: 16px;
            }
        }
        &-btnchat{
            display: inline-block;
            background-color: #40BAB3;
            border-radius: 5px;
            text-align: center;
            padding: 4px;
            margin-left: 10px;
            cursor: pointer;
        }
        &-arrow{
            width: 10px;
            height: 14px;
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            &:before{
                content: "\e902";
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-family: "ntsame";
                position: absolute;
                color: #C8CFD6;
            }
        }
    }
}
