.gallery{
    margin-top: 40px;
    &__wrapper{
        margin: 0px -12px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item{
        width: 33.3%;
        padding: 0 12px;
        margin-bottom: 24px;
        @media (max-width: 1150px){
            width: 50%;
        }
        @media (max-width: 750px){
            width: 100%;
        }
        &-inner{
            // background-color: $light-gray;
            background-color: $turquoise;
            border-radius: 10px;
            padding: 8px;
            display: flex;
            position: relative;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(36, 39, 67, 0.15);
            transition: box-shadow 0.3s;
            &:hover{
                box-shadow: 0px 0px 20px rgba(36, 39, 67, 0.4);
            }
            @media (max-width: 750px){
                max-width: 400px;
                margin: 0 auto;
            }
            &-status{
                position: absolute;
                right: -25px;
                top: -50px;
                width: 112px;
                height: 112px;
                border-radius: 50%;
                background-color: #24E405;
                span{
                    position: absolute;
                    bottom: 25px;
                    left: 24px;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                    color: #F2F6FA;  
                }
                &.offline{
                    background-color: #C8CFD6;
                }
                @media (max-width: 450px){
                    right: -25px;
                    top: -42px;
                    width: 70px;
                    height: 70px;
                    span{
                        position: absolute;
                        bottom: 7px;
                        left: 10px;
                        font-size: 12px;
                    }
                }
            }
        }
        img{
            width: 192px;
            @media (max-width: 750px){
               width: 180px;
            }
        }
        &-info{
            padding-left: 24px;
            padding-top: 40px;
            @media (max-width: 750px){
                padding-top: 0px;
            }
        }
        &-name{
            font-size: 18px;
            line-height: 21px;
            font-weight: 600;
            color: $blue-magenta;
            margin-bottom: 13px;
            transition: color 0.3s;
            &:hover{
                color: rgba(64, 69, 186, 0.2);
            }
            
        }
        &-age{
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            margin-top: 13px;
            span{
                font-weight: 600;
                line-height: 17px;
            }
        }
        &-block{
            margin-top: 15px;
            li{
                margin-bottom: 12px;
                a{
                    display: flex;
                    align-items: center;
                    
                    span{
                        width: 32px;
                        height: 32px;
                        border-radius: 5px;
                        
                        &.chat-icon{
                            background-color: rgba(64, 69, 186, 0.2);
                            position: relative;
                            transition: background-color 0.3s;
                            &:before{
                                position: absolute;
                                content: "\e906";
                                font-family: 'ntsame';
                                font-size: 18px;
                                color: #4045BA;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                transition: color 0.3s;
                            }
                            
                        }
                        &.message-icon{
                            background-color: rgba(206, 73, 208, 0.2);
                            position: relative;
                            transition: background-color 0.3s;
                            &:before{
                                position: absolute;
                                content: "\e917";
                                font-family: 'ntsame';
                                font-size: 18px;
                                color: #CE49D0;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                transition: color 0.3s;
                            }

                        }
                        &.clips-icon{
                            background-color: rgba(237, 110, 127, 0.2);
                            position: relative;
                            transition: background-color 0.3s;
                            &:before{
                                position: absolute;
                                content: "\e92c";
                                font-family: 'ntsame';
                                font-size: 18px;
                                color: #ED6E7F;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                transition: color 0.3s;
                            }
                        }
                    }
                    p{
                        font-size: 12px;
                        line-height: 14px;
                        padding-left: 12px;
                        color: $blue-magenta;
                    }
                    &:hover{
                        .chat-icon{
                            background-color: #4045BA;
                            &:before{
                                color: $white;
                            }
                        }
                        .message-icon{
                            background-color: #CE49D0;
                            &:before{
                                color: $white;
                            }
                        }
                        .clips-icon{
                            background-color: #ED6E7F;
                            &:before{
                                color: $white;
                            }
                        }
                        
                    }
                }  
            }
            li:last-child{
                margin-bottom: 0;
            }
        }
        &-id{
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            margin-top: 22px;
        }
    }
}