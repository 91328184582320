// @font-face {
//   font-family: 'icomoon';
//   src:  url('../../fonts/icomoon.eot?mrhy8w');
//   src:  url('../../fonts/icomoon.eot?mrhy8w#iefix') format('embedded-opentype'),
//     url('../../fonts/icomoon.ttf?mrhy8w') format('truetype'),
//     url('../../fonts/icomoon.woff?mrhy8w') format('woff'),
//     url('../../fonts/icomoon.svg?mrhy8w#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// @font-face {
//   font-family: 'ntsame';
//   src:  url('../../fonts/icons/ntsame.eot?6hgw0o');
//   src:  url('../../fonts/icons/ntsame.eot?6hgw0o#iefix') format('embedded-opentype'),
//     url('../../fonts/icons/ntsame.ttf?6hgw0o') format('truetype'),
//     url('../../fonts/icons/ntsame.woff?6hgw0o') format('woff'),
//     url('../../fonts/icons/ntsame.svg?6hgw0o#ntsame') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// [class^="nts-"], [class*=" nts-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'ntsame' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .nts-arrow-back:before {
//   content: "\e900";
//   color: #fff;
// }
// .nts-arrow-down:before {
//   content: "\e901";
//   color: #777;
// }
// .nts-arrow-right:before {
//   content: "\e902";
//   color: #fff;
// }
// .nts-arrow-slick:before {
//   content: "\e903";
//   color: #352f55;
// }
// .nts-camera-icon:before {
//   content: "\e904";
//   color: #4b4dc8;
// }
// .nts-card-icon:before {
//   content: "\e905";
//   color: #fff;
// }
// .nts-chat-icon:before {
//   content: "\e906";
//   color: #4045ba;
// }
// .nts-chat-start:before {
//   content: "\e907";
//   color: #fff;
// }
// .nts-check-mark-icon:before {
//   content: "\e908";
// }
// .nts-clip-icon:before {
//   content: "\e909";
//   color: #4045ba;
// }
// .nts-contact-icon:before {
//   content: "\e90a";
//   color: #91b0e2;
// }
// .nts-credits-icon:before {
//   content: "\e90b";
//   color: #fff;
// }
// .nts-delete-icon:before {
//   content: "\e90c";
//   color: #ef4040;
// }
// .nts-drafts-icon:before {
//   content: "\e90d";
//   color: #4045ba;
// }
// .nts-edit-icon:before {
//   content: "\e90e";
//   color: #4045ba;
// }
// .nts-facebook:before {
//   content: "\e90f";
//   color: #2d4486;
// }
// .nts-favorite-icon:before {
//   content: "\e910";
//   color: #e1db4b;
// }
// .nts-inbox-icon:before {
//   content: "\e911";
//   color: #fff;
// }
// .nts-interest:before {
//   content: "\e912";
//   color: #ef4040;
// }
// .nts-login-icon:before {
//   content: "\e913";
//   color: #4db0fe;
// }
// .nts-log-out-icon:before {
//   content: "\e914";
//   color: #fff;
// }
// .nts-manage-icon .path1:before {
//   content: "\e915";
//   color: rgb(252, 87, 59);
// }
// .nts-manage-icon .path2:before {
//   content: "\e916";
//   margin-left: -1em;
//   color: rgb(249, 140, 121);
// }
// .nts-message-icon:before {
//   content: "\e917";
//   color: #ce49d0;
// }
// .nts-monets-icon:before {
//   content: "\e918";
//   color: #4045ba;
// }
// .nts-personal-icon:before {
//   content: "\e919";
//   color: #5597fe;
// }
// .nts-photo-icon:before {
//   content: "\e91a";
//   color: #fff;
// }
// .nts-preferences-icon:before {
//   content: "\e91b";
//   color: #fff;
// }
// .nts-present-icon:before {
//   content: "\e91c";
//   color: #e2c291;
// }
// .nts-preview-icon:before {
//   content: "\e91d";
//   color: #be63f9;
// }
// .nts-profiles-icon:before {
//   content: "\e91e";
//   color: #91b0e2;
// }
// .nts-puzzle-icon:before {
//   content: "\e91f";
//   color: #a549d0;
// }
// .nts-search-icon .path1:before {
//   content: "\e920";
//   color: rgb(64, 186, 179);
// }
// .nts-search-icon .path2:before {
//   content: "\e921";
//   margin-left: -1em;
//   color: rgb(83, 217, 209);
// }
// .nts-search-icon .path3:before {
//   content: "\e922";
//   margin-left: -1em;
//   color: rgb(83, 217, 209);
// }
// .nts-search-icon .path4:before {
//   content: "\e923";
//   margin-left: -1em;
//   color: rgb(83, 217, 209);
// }
// .nts-sent-letter-icon:before {
//   content: "\e924";
//   color: #4045ba;
// }
// .nts-slavic-icon:before {
//   content: "\e925";
//   color: #a549d0;
// }
// .nts-special-icon:before {
//   content: "\e926";
//   color: #a549d0;
// }
// .nts-trash-icon:before {
//   content: "\e927";
//   color: #4045ba;
// }
// .nts-unread-icon:before {
//   content: "\e928";
//   color: #4045ba;
// }
// .nts-upload-icon:before {
//   content: "\e929";
//   color: #4b4dc8;
// }
// .nts-video-clips-icon .path1:before {
//   content: "\e92a";
//   color: rgb(64, 69, 186);
// }
// .nts-video-clips-icon .path2:before {
//   content: "\e92b";
//   margin-left: -1em;
//   color: rgb(127, 131, 235);
// }
// .nts-video-icon:before {
//   content: "\e92c";
//   color: #ed6e7f;
// }


@font-face {
  font-family: 'ntsame';
  src:  url('../fonts/icons/ntsame.eot?y4f9k9');
  src:  url('../fonts/icons/ntsame.eot?y4f9k9#iefix') format('embedded-opentype'),
    url('../fonts/icons/ntsame.ttf?y4f9k9') format('truetype'),
    url('../fonts/icons/ntsame.woff?y4f9k9') format('woff'),
    url('../fonts/icons/ntsame.svg?y4f9k9#ntsame') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="nts-"], [class*=" nts-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ntsame' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nts-star-icon:before {
  content: "\e901";
  color: #ffa532;
}
.nts-shine-icon:before {
  content: "\e92d";
  color: #40bab3;
}
.nts-play-icon:before {
  content: "\e92e";
  color: #f5f5f5;
}
.nts-search-icon1:before {
  content: "\e92f";
  color: #a3a3a3;
}
.nts-empty-star-icon:before {
  content: "\e930";
}
.nts-arrow-btn-icon:before {
  content: "\e931";
  color: #c8cfd6;
}
.nts-smile-icon:before {
  content: "\e932";
  color: #c8cfd6;
}
.nts-scraper-icon:before {
  content: "\e933";
  color: #c8cfd6;
}
.nts-camera-icon1:before {
  content: "\e934";
  color: #40bab3;
}
.nts-arrow-back:before {
  content: "\e900";
  color: #fff;
}
.nts-arrow-down:before {
  content: "\e8fe";
  color: #777;
}
.nts-arrow-right:before {
  content: "\e902";
  color: #fff;
}
.nts-arrow-slick:before {
  content: "\e903";
  color: #352f55;
}
.nts-camera-icon:before {
  content: "\e904";
  color: #4b4dc8;
}
.nts-card-icon:before {
  content: "\e905";
  color: #fff;
}
.nts-chat-icon:before {
  content: "\e906";
  color: #4045ba;
}
.nts-chat-start:before {
  content: "\e907";
  color: #fff;
}
.nts-check-mark-icon:before {
  content: "\e908";
}
.nts-clip-icon:before {
  content: "\e909";
  color: #4045ba;
}
.nts-contact-icon:before {
  content: "\e90a";
  color: #91b0e2;
}
.nts-credits-icon:before {
  content: "\e90b";
  color: #fff;
}
.nts-delete-icon:before {
  content: "\e90c";
  color: #ef4040;
}
.nts-drafts-icon:before {
  content: "\e90d";
  color: #4045ba;
}
.nts-edit-icon:before {
  content: "\e90e";
  color: #4045ba;
}
.nts-facebook:before {
  content: "\e90f";
  color: #2d4486;
}
.nts-favorite-icon:before {
  content: "\e910";
  color: #e1db4b;
}
.nts-inbox-icon:before {
  content: "\e911";
  color: #fff;
}
.nts-interest:before {
  content: "\e912";
  color: #ef4040;
}
.nts-login-icon:before {
  content: "\e913";
  color: #4db0fe;
}
.nts-log-out-icon:before {
  content: "\e914";
  color: #fff;
}
.nts-manage-icon .path1:before {
  content: "\e915";
  color: rgb(252, 87, 59);
}
.nts-manage-icon .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(249, 140, 121);
}
.nts-message-icon:before {
  content: "\e917";
  color: #ce49d0;
}
.nts-monets-icon:before {
  content: "\e918";
  color: #4045ba;
}
.nts-personal-icon:before {
  content: "\e919";
  color: #5597fe;
}
.nts-photo-icon:before {
  content: "\e91a";
  color: #fff;
}
.nts-preferences-icon:before {
  content: "\e91b";
  color: #fff;
}
.nts-present-icon:before {
  content: "\e91c";
  color: #e2c291;
}
.nts-preview-icon:before {
  content: "\e91d";
  color: #be63f9;
}
.nts-profiles-icon:before {
  content: "\e91e";
  color: #91b0e2;
}
.nts-puzzle-icon:before {
  content: "\e91f";
  color: #a549d0;
}
.nts-search-icon .path1:before {
  content: "\e920";
  color: rgb(64, 186, 179);
}
.nts-search-icon .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(83, 217, 209);
}
.nts-search-icon .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(83, 217, 209);
}
.nts-search-icon .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(83, 217, 209);
}
.nts-sent-letter-icon:before {
  content: "\e924";
  color: #4045ba;
}
.nts-slavic-icon:before {
  content: "\e925";
  color: #a549d0;
}
.nts-special-icon:before {
  content: "\e926";
  color: #a549d0;
}
.nts-trash-icon:before {
  content: "\e927";
  color: #4045ba;
}
.nts-unread-icon:before {
  content: "\e928";
  color: #4045ba;
}
.nts-upload-icon:before {
  content: "\e929";
  color: #4b4dc8;
}
.nts-video-clips-icon .path1:before {
  content: "\e92a";
  color: rgb(64, 69, 186);
}
.nts-video-clips-icon .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(127, 131, 235);
}
.nts-video-icon:before {
  content: "\e92c";
  color: #ed6e7f;
}

