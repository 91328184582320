.footer{
    background-color: $blue;
    padding-top: 56px;
    &__content{
        background-color: $primary
    }
    &__wrapper{
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 700px){
            flex-direction: column;
        }
    }
    &__item{
        &-left{
            display: flex;
            flex-direction: column;
            // max-width: 420px;
            // width: 100%;
            width: 35%;
            @media (max-width: 700px){
                width: 100%;
            }
        }
        &-subscribe{
            color: $white;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            // margin-bottom: 42px;
        }
        &-letter{
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            margin-top: 42px;
        }
        &-input{
            margin-top: 20px;
            position: relative;
            width: 100%;
            @media (max-width: 700px){
                max-width: 450px;
            }
        }
        &-email{
            border: none;
            outline: none;
            width: 100%;
            font-size: 14px;
            line-height: 48px;
            padding-left: 12px;
            padding-right: 40px;
            color: $white;
            background-color: $blue;
            border-radius: 10px;
            &::placeholder{
                color: rgba(255, 255, 255, 0.5);
            }
            &-btn{
                width: 15px;
                height: 15px;
                // background-color: red;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(0, -50%);
                &:before{
                    position: absolute;
                    font-family: 'ntsame';
                    content: "\e902";
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        &-checkbox{
            display: flex;
            align-items: center;
            margin-top: 16px;
            // input{
            //     background-color: red;
            // }
            &-input{
                width: 24px;
                height: 24px;
                border-radius: 5px;
                
            }
            &-text{
                font-size: 14px;
                line-height: 17px;
                color: $white;
                font-weight: 600;
                margin-left: 12px;
                @media (max-width: 950px){
                    max-width: 270px;
                }
            }
        }
        &-right{
            padding-top: 8px;
            display: flex;
            justify-content: space-around;
            // width: 100%;
            width: 65%;
            @media (max-width: 700px){
                width: 100%;
                padding-top: 30px;
                flex-direction: column;
            }
        }
        &-block{
            li{
                margin-bottom: 14px;
                a{
                    font-size: 18px;
                    line-height: 21px;
                    color: $white;
                    font-weight: 500;
                }
            }
        }
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        margin-top: 64px;
        padding-bottom: 32px;
        @media (max-width: 700px){
            flex-direction: column;
        }
        &-left{
            display: flex;
            @media (max-width: 700px){
                flex-direction: column;
            }
            &-privacy{
                font-size: 14px;
                line-height: 16px;
                color: rgba(255, 255, 255, 0.8);
            }
            &-terms{
                font-size: 14px;
                line-height: 16px;
                color: rgba(255, 255, 255, 0.8);
                margin-left: 24px;
                @media (max-width: 700px){
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        }
        &-right{
            font-size: 14px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.8);
            @media (max-width: 700px){
                margin-top: 10px;
            }
        }
    }
    
}