.top{
    border-top: 1px solid red;
    // background-color: $primary;
    padding-top: 135px;
    padding-bottom: 115px;
    // padding-bottom: 190px;
    h3{
        font-size: 24px;
        line-height: 29px;
        color: $white;
        margin-bottom: 25px;
        @media (max-width: 900px){
            text-align: center;
        }
    }
    &__wrapper{
        display: flex;
        @media (max-width: 900px){
            flex-direction: column;
        }
    }
    &__left{
        max-width: 288px;
        width: 100%;
        @media (max-width: 900px){
            max-width: 500px;
            margin: 0 auto;
        }
        &-col{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0px;
            }
            input{
                width: 100%;
                padding-left: 12px;
                padding-right: 12px;
                line-height: 44px;
                background-color: rgba(255, 255, 255, 0.1);
                outline: none;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 10px;
                font-weight: 300;
                color: $white;
                &::placeholder{
                    color: $white;
                }
            }
            p{
                color: $white;
                font-size: 14px;
                line-height: 16px;
                width: 100%;
            }
            .btn{
                width: 100%;
            }
            &:nth-child(6){
                &:hover{
                      a{
                        color: $violet;
                    }
                }
              
            }
            &:last-child .btn{
                position: relative;
                &:before{
                    position: absolute;
                    font-family: "ntsame";
                    content: "\e90f";
                    color: #2D4486;
                    left: 15px;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-size: 24px;
                    // z-index: 10;
                } 
            }
            .btn-wrap{
                display: flex;
                justify-content: space-between;
                margin: 0 -8px;
                .btn__item{
                    padding: 0 8px;
                    width: 50%;
                    a{
                        width: 100%;
                    }
                }
            }
        }
        strong{
            display: block;
            text-align: center;
            color: $white;
            line-height: 17px;
            font-weight: 600;
            margin: 12px 0;
        }
    }
    &__right{
        max-width: 912px;
        min-height: 428px;
        height: 100%;
        width: 100%;
        height: auto;
        border: 8px solid $white;
        border-radius: 20px;
        margin-left: 24px;
        background-image: url("../img/top-image.png");
        background-position: center;
        background-size: cover;
        @media (max-width: 900px){
            margin-left: 0px;
            margin-top: 20px;
        }
         @media (max-width: 700px){
            min-height: 350px;
        }
        h3{
            margin-top: 45px;
            // background-color: rgba(255, 255, 255, 0.3);btn
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            padding: 24px 32px;
            color: $blue-magenta;
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
        }
        &-item{
            ul{
                // background-color: rgba(255, 255, 255, 0.3);
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
                padding: 16px 32px;
                li{
                    font-size: 18px;
                    line-height: 29px;
                    color: $blue-magenta;
                    font-weight: 600;
                }
                li:nth-child(3),
                li:nth-child(4){
                    text-transform: uppercase;
                }
                li:nth-child(4) span{
                    text-transform: capitalize;
                }
            }
        }
    } 
}


