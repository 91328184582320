.header{
    height: 80px;
    background: #5358c1;
    // background: rgba(255, 255, 255, 0.1);
    // backdrop-filter: blur(100px);
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 15;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }
    &__logo{
        a{
            font-size: 24px;
            line-height: 29px;
            font-weight: 600;   
        } 
    }
    &__btn-open{
        width: 30px;
        height: 30px;
        // background-color: green;
        display: none;
        position: relative;
        right: 4px;
        span,
        span:before,
        span:after{
            width: 20px;
            height: 2px;
            background-color: $white;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -10px;
            margin-top: -1px;
        }
        span:before,
        span:after{
            content: "";
            display: block;
            transition: all 0.2s;
        }
        span:before{
            transform: translateY(-6px);
        }
        span:after{
            transform: translateY(6px);
        }
        span.active:before{
            transform: rotate(45deg);
        }
        span.active:after{
            transform: rotate(-45deg);
        }
        span.active{
            background-color: transparent;
        }





        @media (max-width: 900px){
            display: block;
        }
    }
    &__nav{
        @media (min-width: 901px){
            display: block !important;
        }
       
        @media (max-width: 900px){
            // display: none;
            position: absolute;
            background-color: grey;
            left: -100%;
            top: 80px;
            width: 100%;
            min-height: 420px;
            overflow-y: scroll;
            transition: all .3s;
            height: 100vh;
        }
        &.open{
            @media (max-width: 900px){
                left: 0;
            }
        }       
        &-item{
            display: flex;
            font-weight: 600;
            align-items: center;
            @media (max-width: 900px){
                flex-direction: column;
            }
            li{
                // padding: 8px 16px;
                border-radius: 10px;
                cursor: pointer;
                a{
                    color: $black;
                    padding: 7.5px 16px;
                    line-height: 32px;
                }

            }
            li:first-child{
                background-color: $white;
                @media (max-width: 900px){
                    margin-top: 30px;
                }
                a{
                    color: $primary;
                }
            }
            li:last-child{
                border: 2px solid $black;
                background-color: $violet;
                width: 116px;
                line-height: 38px;
                text-align: center;
                padding: 0px;
                border-radius: 15px;
                transition: all .3s;
                &:hover{
                    background-color: transparent;
                }
            }
            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4),
            li:nth-child(5){
                margin-left: 35px;
                @media (max-width: 900px){
                    margin-left: 0px;
                    margin-top: 20px;
                }
                a{
                    color: $white;
                    transition: all .3s;
                }
                
            }

            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4){
                transition: all .3s;
                &:hover{
                    background-color: $white;
                    a{
                        color: $primary;
                        transition: all .3s;
                    }
                }
                
            } 
        }
    }
}