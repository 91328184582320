.search{
    &__title{
        font-size: 36px;
        line-height: 43px;
        color: $blue-magenta;
        font-weight: 600;
        margin-top: 55px;
        //  min-width: 320px;
        // overflow-x: hidden;
    }
    &__wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -12px;
        margin-top: 15px;
        margin-bottom: 30px;
    }
    &__item{
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        &-title{
            font-size: 14px;
            line-height: 17px;
            color: $blue-magenta;
        }
        &-button{
            flex-grow: 1;
            .btn{
                width: 100%;
                @media (max-width: 1250px){
                    max-width: 140px;
                    width: 100%;
                }
            }
        }
    }
    &__find{
        &-text{
            font-size: 14px;
            line-height: 16px;
            color: $blue-magenta;
        }
    }
}